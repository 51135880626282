.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 40px;
}

/* Overlay for gallery items */
.g-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  width: 30.33%; /* Three images per row */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.15) rotate(-3deg); /* Zoom and rotate effect */
}

.gallery-image {
  width: 100%;
  height: auto;
}

.gallery-item:hover .overlay {
  opacity: 1;
}

.caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1;
}

.g-ul {
  list-style-type: none;
  padding: 0;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.g-ul li {
  margin-right: 20px;
  cursor: pointer;
  color: #ff5722;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.g-ul li:hover {
  color: #060606; /* Change color on hover */
}

.g-ul li:last-child {
  margin-right: 0; /* Remove margin for the last item */
}

.zoom-out-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.gallery-item:hover .zoom-out-icon {
  opacity: 1; /* Show icon on hover */
}

.zoom-out-icon:hover {
  transform: scale(1.2); /* Zoom effect on icon hover */
}

/* Slideshow Modal */
.slideshow-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.slideshow-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  overflow: hidden;
}

.slideshow-content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slideshow-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 36px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 1001;
}

.slideshow-control.left {
  left: 20px;
}

.slideshow-control.right {
  right: 20px;
}

.slideshow-control:hover {
  opacity: 0.8;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 1001;
}

.close-icon:hover {
  opacity: 0.8;
}

/* Responsive Design */
@media (max-width: 768px) {
  .gallery-item {
      width: 48%; /* Two images per row */
  }
  
  .g-ul {
      flex-direction: column;
      align-items: center;
  }

  .g-ul li {
      margin-bottom: 10px;
      margin-right: 0;
  }
  
}

@media (max-width: 480px) {
  .gallery-item {
      width: 100%; /* One image per row */
  }
  
  .g-ul {
      flex-direction: column;
      align-items: center;
  }

  .g-ul li {
      margin-bottom: 10px;
      margin-right: 0;
  }
}

.g-ul {
  list-style-type: none;
}

.g-ul li {
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
}

.g-ul li .dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 150px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.g-ul li .dropdown-content li {
  padding: 12px 16px;
  cursor: pointer;
  font-size: smaller;
}

.g-ul li .dropdown-content li:hover {
  background-color: #191515;
  color: #ff5722;
}

.g-ul li:hover .dropdown-content {
  display: block;
}

.video-container {
  display: flex;
  padding: 50px;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px; /* Adjust the gap between videos as needed */
}

.video-item {
  flex: 1 1 calc(50% - 20px); /* Two videos per row with gap */
  box-sizing: border-box;
  margin-bottom: 20px; /* Adjust the bottom margin as needed */
}

.video-item video {
  width: 100%;
  height: auto;
  display: block;
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
  .video-item {
    flex: 1 1 100%; /* One video per row on mobile */
  }
}
