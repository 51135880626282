/* File: src/components/HsseVision.css */

.hsse-vision-container {
    padding: 20px;
    text-align: center;
    max-width: 1200px;
    margin: auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .hsse-vision-title {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .highlight {
    color: #F05426;
  }
  
  .hsse-vision-description {
    font-size: 1.1em;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  
  .hsse-vision-subtitle {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .hsse-vision-term {
    font-size: 1.2em;
    margin-bottom: 30px;
  }
  
  .hsse-vision-items {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .hsse-vision-item {
    flex: 1 1 calc(25% - 20px);
    background-color: #f9f9f9;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .vision-number {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .orange {
    color: #F05426;
  }
  
  .black {
    color: black;
  }
  