.news-ticker-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    overflow: hidden;
    position: sticky;
    top: 100vh; /* Positioned beneath the slider */
    z-index: 10;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .news-header {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 100%;
  }
  
  .news-ticker {
    width: calc(100% - 100px); /* Adjust this based on news header width */
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }
  
  .news-items {
    display: inline-block;
    animation: slide 60s linear infinite;
  }
  
  .news-item {
    display: inline-block;
    padding: 0 50px;
  }
  
  @keyframes slide {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  