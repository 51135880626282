.App-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  transition: background-color 0.3s ease;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App-header .top-row {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

.App-header .bottom-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  font-size: smaller;
}

.bottom-row img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.App-header.scrolled .bottom-row {
  background-color: rgba(0, 0, 0, 0.8);
}

.App-header.scrolled .top-row {
  background-color: rgba(0, 0, 0, 0.8);
}

.header-button {
  background: #F05426;
  border: none;
  color: white;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  font-size: smaller;
  transition: background-color 0.3s ease;
}

.header-button:hover {
  background: rgb(255, 119, 0);
}

.logo {
  font-size: 1.2em;
  font-weight: bold;
  color: white;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.nav a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}

.nav a:hover {
  text-decoration: underline;
  color: #F05426;
}

.header-mail {
  color: aliceblue;
  font-size: smaller;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #F05426;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content li {
  color: white;
  padding: 5px;
  text-decoration: none;
  display: block;
}

.dropdown-content li:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.top-row .social-icons{
  color: white;
  padding-right: 10px;
}
.top-row .social-icons :hover {
  color: #F05426;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .top-row {
    display: none;
  }

  .menu-toggle {
    display:flex;
  }

  .bottom-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav.open {
    display: flex;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    color: white;
    font-size: 2em;
    cursor: pointer;
  }

  .nav ul {
    flex-direction: column;
    width: 100%;
  }

  .nav ul li {
    width: 100%;
    text-align: left;
    padding: 10px 20px;
  }

  .nav ul li a {
    width: 100%;
    display: block;
  }

  .dropdown-content {
    position: static;
    background-color: transparent;
    box-shadow: none;
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .dropdown:hover .dropdown-content {
    display: flex;
  }
}

.menu-toggle {
  background-color: transparent;
  border: none;
  color: #F05426;
}
