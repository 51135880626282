/* General styles for the manager cards */
.managers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.manager-card {
  width: calc(20% - 20px); /* Adjust the width based on your design */
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid #F05426;
}

.manager-image {
  width: 100%;
  height: auto;
  max-width: 200px; /* Adjust the max-width based on your design */
  margin-bottom: 15px;
  border: 1px solid #0B4377;
  border-radius: 5px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icons a {
  color: #F05426;
  font-size: 20px;
}

.social-icons a:hover {
  color: #0B4377;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .manager-card {
    width: calc(25% - 20px); /* Adjust for larger screens */
  }
}

@media (max-width: 992px) {
  .manager-card {
    width: calc(33.33% - 20px); /* Adjust for medium screens */
  }
}

@media (max-width: 768px) {
  .manager-card {
    width: calc(50% - 20px); /* Adjust for smaller screens */
  }
}

@media (max-width: 576px) {
  .manager-card {
    width: calc(100% - 20px); /* Full width for mobile screens */
  }
}

/* Adjust the padding and gap for mobile devices */
@media (max-width: 576px) {
  .managers-container {
    gap: 10px;
  }

  .manager-card {
    padding: 10px 5px;
  }
}
