.kodson-info {
  margin-top: 50px;
  font-family: Arial, sans-serif;
  width: 100%;
}

.section {
  margin-bottom: 2em;
}

.who-we-are {
  display: flex;
  flex-direction: column;
  padding: 2em;
  font-size: smaller;
  align-items: center;
}

.who-we-are .text {
  padding: 1em;
  text-align: center;
}

.who-we-are .image {
  width: 100%;
  text-align: center;
}

.who-we-are .image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.who-we-are button {
  background-color: rgb(255, 64, 0);
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 1em;
}

.our-services1 {
  text-align: center;
  padding: 2em;
  background-color: rgb(7, 7, 103);
  color: #fff;
}

.our-services1 .services-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.our-services1 .title-line {
  width: 5px;
  height: 50px;
  background-color: #F05426;
  margin-right: 10px;
}

.title-line {
  width: 5px;
  height: 50px;
  background-color: #F05426;
  margin-right: 10px;
}

.our-services1 h2, .our-services1 h3 {
  margin-bottom: 1em;
  color: white;
}

.services1-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service1 {
  flex: 1;
  max-width: 200px;
  margin: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service1 .icon {
  font-size: 50px;
  margin-bottom: 1em;
  color: #F05426;
}

.service1 h4 {
  color: #F05426;
  margin-bottom: 0.5em;
}

.service1 p {
  flex: 1;
  text-align: center;
  margin: 0 0 1em 0;
}

.service1 button {
  background-color: #F05426;
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  cursor: pointer;
  border-radius: 5px;
  margin-top: auto;
}

.service1 button:hover {
  background-color: #ff7f00;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .who-we-are {
    flex-direction: row;
  }

  .who-we-are .text {
    flex: 1;
    padding: 0 5em 0 2em;
    text-align: left;
  }

  .who-we-are .image {
    flex: 1;
  }

  .who-we-are .image img {
    width: 70%;
    height: auto;
  }

  .services1-grid {
    justify-content: space-around;
  }
}
