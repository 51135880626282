/* src/components/Contact.css */
.contact-container {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-container h2 span {
  color: #F05426;
}

.contact-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.company-info {
  flex: 1;
  max-width: 100%;
  padding-right: 0;
  line-height: 1.6;
}

.contact-form {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.form-group textarea {
  height: 100px;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #F05426;
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

button[type="submit"]:hover {
  background-color: darkorange;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #F05426;
}

.checkbox-message {
  color: #F05426;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .contact-container {
    padding: 50px;
  }

  .contact-content {
    flex-direction: row;
  }

  .company-info {
    max-width: 45%;
    padding-right: 20px;
  }

  .contact-form {
    max-width: 45%;
  }

  .checkbox-group input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1024px) {
  .contact-container {
    padding: 100px;
  }

  .checkbox-group input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
}
