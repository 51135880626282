* {
  box-sizing: border-box;
}



.about-page1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
}

.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
}

.content-container1 {
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;
}

.story-section1,
.mission-vision-section1,
.core-values-section1,
.collapsible-sections1 {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
}

.section-title1 {
  font-size: 1.5em;
  color: #F05426;
  border-left: 5px solid #F05426;
  padding-left: 10px;
  margin-bottom: 10px;
}

.story-text1 {
  font-size: 1em;
  color: #333;
}

.mission-vision-section1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.mission-box1, .vision-box1 {
  background-color: rgb(7, 7, 103);;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.mission-box1 h2, .vision-box1 h2 {
  color: #F05426;
  margin-bottom: 10px;
}

.core-values-section1 h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: rgb(7, 7, 103);;
}

.core-values-section1 p {
  font-size: 1.2em;
  color: rgb(7, 7, 103);;
}

.core-values-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.core-value-box {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  text-align: left;
  color: white;
}

.core-value-box h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  text-align: center;
}

.core-value-box ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.core-value-box li {
  margin-bottom: 5px;
}

.core-value-box-good {
  background: linear-gradient(to bottom, #ff8c00, #F05426);
}

.core-value-box-originality {
  background: linear-gradient(to bottom, rgb(7, 7, 103), rgb(9, 9, 135));
}

.core-value-box-integrity {
  background: linear-gradient(to bottom, #ff8c00, #F05426);
}

.core-value-box-leadership {
  background: linear-gradient(to bottom, rgb(7, 7, 103), rgb(9, 9, 135));
}

.collapsible-sections1 {
  margin-top: 20px;
}

.section1 {
  border: 1px solid #F05426;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.section1 h3 {
  background-color: #F05426;
  color: white;
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section1 p {
  padding: 10px;
  margin: 0;
}

.quick-links1 {
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;
}

.quick-links1 h2 {
  font-size: 1em;
  color: #333;
  margin-bottom: 10px;
}

.quick-link1 {
  display: block;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  background-color: #F05426;
}

.quick-link1:hover {
  background-color: #F05426;
  color: white;
}

@media (min-width: 768px) {
  .main-content {
    flex-direction: row;
  }

  .content-container1 {
    width: 70%;
    margin-right: 20px;
  }

  .core-values-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .core-value-box {
    width: 23%;
  }

  .quick-links1 {
    width: 25%;
  }
}
