body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.banner {
  width: 100%;
  height: 60vh; /* Full viewport height */
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  text-align: center;
}

.caption h1 {
  font-size: 3em;
  margin: 0;
  padding: 10px 20px;
  border-radius: 5px;
}
