.hsse-policy-container {
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.hsse-policy-content {
  max-width: 70%;
  text-align: left;
}

.quick-links {
  width: 230px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  margin-left: 20px;
}

.quick-links h2 {
  margin-bottom: 10px;
}

.quick-links ul {
  list-style-type: none;
  padding: 0;
}

.quick-links li {
  margin-bottom: 10px;
}

.quick-links a {
  text-decoration: none;
  color: white;
  background-color: #F05426;
  padding: 10px;
  display: block;
  text-align: center;
}

.quick-links a:hover {
  background-color: darkorange;
}

.certificate-section {
  text-align: center;
  margin-top: 50px;
  padding: 100px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.certificate-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-top: 20px;
}

.title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.highlight {
  color: #F05426;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hsse-policy-container {
    padding: 50px;
    flex-direction: column;
    align-items: flex-start;
  }

  .hsse-policy-content {
    max-width: 100%;
  }

  .quick-links {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .certificate-section {
    padding: 50px;
  }
}

@media (max-width: 480px) {
  .hsse-policy-container {
    padding: 30px;
  }

  .title {
    font-size: 1.5em;
  }

  .quick-links a {
    font-size: 0.9em;
    padding: 8px;
  }

  .certificate-section {
    padding: 30px;
  }
}
