.vertical-icons-container {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease, width 0.3s ease, padding 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}

.icon-container.expanded {
  width: auto;
  padding-right: 10px;
  /* background-color: transparent;
  color: black; */
  transform: translateX(-100px);
  width: 100%;
  height: 50px;
  border-radius: 5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.icon-container.expanded:hover{
  color: #fff;
}

.icon-container.expanded .icon-style{
  width: 20px;
  height: auto;
  color: #fff;
  margin-right: 5px;
  animation: heartBeat 1.5s ease-in-out infinite;
}

.icon-style {
  width: 20px;
  height: auto;
  color: #fff;
}

.contact-text {
  color: #fff;
  font-size: 14px;
}

.blue-bg {
  background-color: #007bff;
}

.red-bg {
  background-color: #F05426;
}

.green-bg {
  background-color: #16bf10;
}

.icon-container:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-header {
  font-size: 1.5em;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form div {
  margin-bottom: 10px;
}

.contact-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.contact-form button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}

.modal-content {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  align-items: center;
}

@media (max-width: 600px) {
  .modal-content {
    padding: 10px;
    width: 175%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .modal-header {
    font-size: 1.2em;
  }

  .contact-form label,
  .contact-form input,
  .contact-form textarea {
    font-size: 0.9em;
    width: 100%;
  }

  .contact-form button {
    font-size: 0.9em;
    width: 55%;
  }

  .modal-close {
    font-size: 1em;
  }
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes heartBeat {
  0%, 100% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
}
