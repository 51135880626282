/* Footer Styles */
.footer {
  background-color: rgb(7, 7, 103);
  color: #fff;
  padding: 40px 0;
  margin-top: 10px;
  border-top: 1px solid #F05426;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-column {
  width: 25%;
  margin-bottom: 30px;
}

.footer-column h3 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: bold;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
}

.social-icons {
  display: flex;
  padding: 0;
}

.social-icons li {
  margin-right: 10px;
  color: #F05426;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #F05426;
}

/* Custom Bullet Styles */
.custom-bullet {
  list-style-type: none;
  padding: 0;
}

.custom-bullet li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 8px;
}

.custom-bullet li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 8px 5px 0;
  border-color: transparent #F05426 transparent transparent;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-column {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .footer-column {
    width: 100%;
  }
}
