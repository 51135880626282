.slider-container {
  width: 100%;
  height: 100vh; /* Make slider cover entire screen */
  overflow: visible;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.slider {
  width: auto;
  height: auto;
  overflow: visible;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transform: scale(1);
  transition: opacity 1s ease-in-out, transform 7s ease-in-out;
}

.slide.active {
  opacity: 1;
  transform: scale(1.06); /* Zoom effect */
}

.slide-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2em;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5); /* Full background with transparency */
  padding: 20px;
  box-sizing: border-box;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

