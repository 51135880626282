.hsse-profile {
  padding: 100px;
  font-family: Arial, sans-serif;
}

.hsse-profile h1 {
  color: #F05426;
}

.hsse-profile h2 {
  margin-top: 20px;
}

.hsseq-story,
.hsse-excellence,
.our-vision,
.health-wellbeing,
.iso-certification {
  margin-bottom: 20px;
}

.hsseq-story p,
.hsse-excellence p,
.our-vision p,
.health-wellbeing p,
.iso-certification p {
  font-size: 16px;
  line-height: 1.5;
}

.hsse-excellence ol {
  margin-left: 20px;
}

.hsse-excellence li {
  margin-bottom: 10px;
}

.hsse-excellence strong {
  font-weight: bold;
}

.our-vision .vision-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.our-vision .vision-item {
  text-align: center;
  width: 22%;
}

.our-vision .vision-number {
  font-size: 2em;
  font-weight: bold;
  color: #F05426;
  margin-bottom: 10px;
}

.health-wellbeing-content {
  display: flex;
  justify-content: space-between;
}

.health-wellbeing-item {
  width: 48%;
}

.health-wellbeing-item img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.iso-certification-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  display: block;
  margin-top: 10px;
}

.iso-certification {
  text-align: center;
  margin-top: 50px;
  padding: 100px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hsse-profile {
    padding: 50px;
  }

  .hsse-profile h1 {
    font-size: 1.5em;
  }

  .hsse-profile h2 {
    font-size: 1.2em;
  }

  .our-vision .vision-items {
    flex-direction: column;
  }

  .our-vision .vision-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .health-wellbeing-content {
    flex-direction: column;
  }

  .health-wellbeing-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .iso-certification {
    padding: 50px;
  }
}

@media (max-width: 480px) {
  .hsse-profile {
    padding: 30px;
  }

  .hsse-profile h1 {
    font-size: 1.2em;
  }

  .hsse-profile h2 {
    font-size: 1em;
  }

  .our-vision .vision-number {
    font-size: 1.5em;
  }

  .iso-certification {
    padding: 30px;
  }
}
