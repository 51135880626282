.partners-container {
  padding: 20px;
  background-color: #fff;
  text-align: center;
}

.partners-container h2 {
  color: #070707;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bolder;
  font-size: 24px;
}

.partners-container p {
  color: #080808;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 20px;
}

.partners-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.partner-item {
  width: 180px;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  text-align: center;
  transition: transform 0.3s ease;
}

.partner-item:hover {
  transform: scale(1.05);
}

.partner-item img {
  max-width: 100%;
  height: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .partner-item {
    width: 140px;
  }
}

@media (max-width: 480px) {
  .partner-item {
    width: 100px;
  }
}
